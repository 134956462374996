.main-container {
  height: 100vh;
}

.body-container {
  background-color: white;
  height: 92vh;
  display: flex;
  align-items: center;
}
header.uk-comment-header {
  margin: 0px 0px 0px 0px;
}
a:hover {
  text-decoration: none;
  color: #0d0b5a;
}

a {
  color: #0d0b5a;
}

svg {
  display: inline-block;
  vertical-align: middle;
}

.suggestion-input {
  list-style-type: none;
}

[type="checkbox"]:checked {
  background-color: #0d0b5a;
}

.uk-tab > .uk-active > a {
  border-left-color: #0d0b5a !important;
}

.uk-active a {
  border-bottom-color: #0d0b5a !important;
}

.no-border {
  border: 0px;
}

.spacer {
  width: 0.5vw;
}

.rounded-btn {
  background-color: #0d0b5a;
  color: white;
  border-color: white;
  border-radius: 2rem;
  height: 5vh;
  width: 10vw;
}

.centered {
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  width: auto;
}

.centered-no-back {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
}

.form-container {
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  width: 40vw;
}

.primary-theme-color {
  background-color: #0d0b5a;
}

.primary-theme-color-foreground {
  background-color: white;
  color: #0d0b5a;
}

.full-screen {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.sign-in-form {
  width: 60%;
  height: 40%;
}

.border-radius {
  border-radius: 2rem;
  border-color: grey;
}

.text-white {
  color: white;
}

.tag {
  border-radius: 1rem;
  width: auto;
  height: 3vh;
}

.text-primary {
  color: #0d0b5a;
}

.bottom-border {
  border: none;
  border-bottom: 1px solid #0d0b5a;
}

input.uk-input {
  margin-top: 10px;
  margin-bottom: 10px;
}

.logo-form {
  height: auto;
  width: auto;
}

/* App bar css */

.app-bar {
  display: flex;
  height: 8vh;
  align-items: center;
  justify-content: space-around;
}

.app-bar-logo {
  height: 80px;
  width: 100px;
}

.home-logo,
a.uk-icon {
  color: white;
}

#user-name {
  color: white;
  font-size: 20px;
}

.menu-actions {
  color: white;
}

#menu-btn {
  position: fixed;
  top: 2%;
  left: 10px;
}
#tmp-list-btn-container {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}

#refresh-account-btn {
  position: fixed;
  bottom: 10px;
  right: 10px;
  height: 50px;
  width: 50px;
}
#tmp-list-btn {
  position: fixed;
  bottom: 10px;
  right: 10px;
  height: 50px;
  width: 50px;
}

#refresh-account-btn:hover {
  background-color: #0d0b5a;
  color: grey;
}
#tmp-list-btn:hover {
  background-color: #0d0b5a;
  color: grey;
}

#asfe-consular-tag {
  background-color: #0d0b5a;
  color: white;
}
#sympatizer-tag {
  background-color: #e74c3c;
}
/* Home screen */

.dashboard-menu {
  position: fixed;
  margin-left: 1vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 14vw;
}

.menu-item {
  width: 8vw;
  display: flex;
  align-items: center;
}

.menu-item:hover {
  border-left: 3px solid #0d0b5a;
}

/* Separator */

.divider {
  height: 80vh;
  width: 1px;
  background-color: #0d0b5a;
}

.default-divider {
  border-bottom: 1px solid #0d0b5a;
  width: auto;
  margin: 10px;
}

.spacer-1 {
  width: 0.5vw;
}

.spacer-2 {
  width: 2vw;
}

.sizedbox-width-5 {
  width: 5vw;
}

.sizedbox-width-10 {
  width: 10vw;
}

.sizedbox-height-2 {
  height: 2vh;
}

.sizedbox-height-5 {
  height: 5vh;
}

.sizedbox-height-10 {
  height: 10vh;
}

/* Main screen content */

.content-screen {
  margin-left: 15vw;
  display: flex;
  width: 100vw;
  align-items: center;
  height: 85vh;
  background-color: white;
}

.items-container {
  height: 70vh;
  overflow: scroll;
}

.auto-with {
  width: 80vw;
}

.header-container {
  display: flex;
  width: 82vw;
  align-items: start;
  justify-content: space-evenly;
}

.member-actions-description {
  display: flex;
}

.member-item-list {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: start;
}

.flex-column-space-around {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: space-around;
  align-items: flex-start;
}

#navigation-dot {
  position: relative;
  padding: 1vw;
  left: 35vw;
  bottom: 20px;
}

.inline-content {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  justify-items: center;
  overflow: scroll;
}

.inline-content-center {
  display: flex;
  justify-content: center;
  align-content: space-around;
}

.inline-content-center-no-space {
  display: flex;
  justify-content: center;
  align-content: center;
}

.padding-5 {
  padding: 20px 20px;
}

#dashboard-card {
  min-width: 245px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
}

.flex-row-center {
  display: flex;
  justify-content: center;
  justify-items: center;
}

.row {
  display: flex;
  justify-content: space-between;
  height: 60vh;
}

.circular {
  border-radius: 50%;
}

.list-details-stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 18vh;
  height: 18vh;
}

.list-details-stats-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-20w {
  width: 30vw;
}

.content-30w {
  width: 30vw;
}

.content-70w {
  width: 70vw;
}

.last-campaigns {
  height: 40vh;
}

.font-20 {
  font-size: 20px;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw; /* Full width */
  height: 100vh; /* Full height */
  overflow: scroll; /* Enable scroll if needed */
  background-color: white; /*Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-content {
  width: 85vw;
  height: 80vh;
  background-color: white;
  padding: 5%;
  border-radius: 2rem;
  overflow: scroll;
}

.modal-content::-webkit-scrollbar {
  display: none;
}

.table-container {
  padding-left: 20px;
}
